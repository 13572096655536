<template>
  <div>

    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <router-link :to="{ name: 'new-pages' }">
        <b-button class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none">
          <i class="fas fa-arrow-left mr-25" /> {{ $t("key-126") }}
        </b-button>
      </router-link>
      <div class="row">
        <div class="col-md-8 col-lg-8 col-sm-12">
          <div class="sqxwIi">
            <div class="_3cPNXP">
              <div class="V-sVj2">
                <div class="jNp+ZB ktatB-">
                  <h2 class="_6HCfS6">
                    {{ $t('key-108') }}
                  </h2>
                </div>
                <div class="jNp+ZB _04sLFc" />
                <div class="jNp+ZB">
                  {{ $t('unitPrice') }}
                </div>
                <div class="jNp+ZB">
                  {{ $t('quantity') }}
                </div>
                <div class="jNp+ZB LBqTli">
                  {{ $t('key-109') }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(shop, shopin) in order"
            :key="shopin"
          >
            <div class="Z7qspM mb-1">
              <div class="vYrpLx">
                <div>
                  <div class="lAXOsx _2bC6t1">
                    <img
                      :src="`/svg/${shop.provider}.svg`"
                      alt="icon"
                      width="20"
                    >
                  </div>
                </div>
                <h3 class="YSl9dN mb-0">
                  {{ shop.storename }}
                </h3>

                <div class="_75ySYI">
                  {{ shop.provider }}
                </div>
              </div>
              <div class="KxX-H6">
                <div
                  v-for="(product, productin) in shop.products"
                  :key="productin"
                  class="_2OGC7L xBI6Zm"
                >
                  <div class="h3ONzh EOqcX3">
                    <img
                      class="rTOisL"
                      alt="product image"
                      :src="product.color_img ? product.color_img : product.size_img ? product.size_img : product.picture"
                      width="40"
                      height="40"
                    >
                    <span
                      class="oEI3Ln"
                      style="min-width: 200px !important;"
                    >
                      <span class="gHbVhc">{{ product.name }}</span>
                      <div class="">
                        <p
                          v-if="product.color_title"
                          class="mb-0 small text-muted mt--5"
                        >{{ $t('color') }} : {{ product.color_title }}</p>
                        <p
                          v-if="product.size_title"
                          class="mb-0 small text-muted mt--10"
                        >{{ $t('size') }} : {{ product.size_title }}</p>
                        <p
                          v-if="product.fileimg"
                          style="font-weight: bold;"
                        >ไฟล์แนบ:
                          <img
                            :src="`https://api.anan-cargo.com/api/Order/getimg/${product.fileimg}`"
                            alt="Preview"
                            width="50"
                            class="text-center cursor-pointer"
                            @click="showPicture(`https://api.anan-cargo.com/api/Order/getimg/${product.fileimg}`)"
                          >

                          <!-- <img
                            :src="`http://127.0.0.1:4444/api/Order/getimg/${product.fileimg}`"
                            alt="Preview"
                            width="50"
                            class="text-center"
                            @click="showPicture(`http://127.0.0.1:4444/api/Order/getimg/${product.fileimg}`)"
                          > -->
                        </p>
                        <p
                          v-if="product.remark"
                          style="font-weight: bold;"
                        >หมายเหตุ: {{ product.remark }} </p>
                      </div>
                    </span>
                  </div>

                  <div class="h3ONzh">
                    ¥ {{ Commas(product.price) }}
                  </div>
                  <div class="h3ONzh">
                    {{ Commas(product.quantity) }}
                  </div>
                  <div class="h3ONzh fHRPUO">
                    ¥ {{ Commas(product.price * product.quantity) }}
                  </div>
                </div>
              </div>
              <div class="cETXAA">
                <div class="N5Svcv">
                  <div class="IsPQKt">
                    <div class="U7Xuk4">
                      <b-form-checkbox
                        v-model="packbox"
                        :checked="true"
                        :unchecked-value="false"
                        name="check-button"
                        switch
                        inline
                        @change="ChangeHeadShop(shop,shopin)"
                      >
                        {{ $t('woodPacking') }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="checkproduct"
                        :checked="true"
                        :unchecked-value="false"
                        name="check-button"
                        switch
                        inline
                        @change="ChangeHeadShop(shop, shopin)"
                      >
                        {{ $t('checkOrder') }}
                      </b-form-checkbox>
                      <small>{{ shop.packbox && shop.checkproduct  ? '(QC)(打木架)':shop.packbox ? '(打木架)':shop.checkproduct ? '(QC)': '' }}</small>
                    </div>
                  </div>
                </div>
                <div class="uw1QJu KL2Fv5">
                  <div class="za7qoR">
                    <div class="D2AHAU">
                      <div class="hcC4-3">
                        <div>{{ $t('key-110') }} ({{ shop.products.length }} {{ $t('key-111') }}):</div>
                      </div>
                    </div>
                    <div class="zjjc32">
                      <div>
                        <div>
                          <div class="_9F3E9v">
                            ¥ {{ Commas(shop.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0)) }}
                          </div>
                          <div style="display: contents" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 ">
          <div class="-p7ULT">
            <div class="vtrWey" />
            <div class="_8jJlG8">
              <h5 class="text-primary">
                <i class="fal fa-shipping-fast" /> {{ $t('key-112') }}
                <span class="text-danger">*</span>
              </h5>

              <div
                class="card-tran mt-1"
                :class="{ active: delivery_type === 1 }"
                @click="delivery_type = 1"
              >
                <div class="card-tran-body">
                  <div class="card-tran-body-left ">
                    <img
                      src="/icon/car.svg"
                      alt="icon"
                      width="40"
                      class="mr-1"
                    >
                  </div>
                  <div class="card-tran-body-right">
                    <div class="card-tran-body-right-top">
                      <h5 class="mb-0">
                        {{ $t('key-113') }}
                      </h5>
                      <small class="mb-0">
                        {{ $t('5-7') }}
                      </small>
                    </div>
                  </div>
                </div>

                <b-form-radio
                  v-model="delivery_type"
                  name="some-radios"
                  :value="1"
                  :unchecked-value="0"
                  class="radio-custom"
                />
              </div>

              <div
                class="card-tran mt-1"
                :class="{ active: delivery_type === 2 }"
                @click="delivery_type = 2"
              >
                <div class="card-tran-body">
                  <div class="card-tran-body-left ">
                    <img
                      src="/icon/ship.svg"
                      alt="icon"
                      width="40"
                      class="mr-1"
                    >
                  </div>
                  <div class="card-tran-body-right">
                    <div class="card-tran-body-right-top">
                      <h5 class="mb-0">
                        {{ $t('key-119') }}
                      </h5>
                      <small class="mb-0">
                        {{ $t('15-25') }}
                      </small>
                    </div>
                  </div>
                </div>

                <b-form-radio
                  v-model="delivery_type"
                  name="some-radios"
                  :value="2"
                  :unchecked-value="0"
                  class="radio-custom"
                />
              </div>

              <!-- <b-form-group>
                <b-form-radio
                  v-model="delivery_type"
                  name="some-radios"
                  :value="1"
                  :unchecked-value="0"
                  class="mb-1 mt-1"
                >
                  ทางรถ (ระยะเวลา 5-7 วัน)
                </b-form-radio>

                <b-form-radio
                  v-model="delivery_type"
                  name="some-radios"
                  :value="2"
                  :unchecked-value="0"
                >
                  ทางเรือ (ระยะเวลา 15-25 วัน)
                </b-form-radio>
              </b-form-group> -->

              <div class="mt-1">
                <label for="keyword">{{ $t('mnemonic') }}</label>
                <b-form-textarea
                  id="keyword"
                  v-model="note"
                  class="form-control"
                  rows="2"
                />
              </div>

              <div class="text-right mt-1">
                {{ $t('key-121') }} ({{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + ele2.quantity, 0), 0)) }} {{ $t('item') }})
              </div>

              <div
                class="text-right"
                style="margin-top: 8px;"
              >
                <span class="cart-page-footer-summary__subtotal-text">¥ {{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + (ele2.price*ele2.quantity), 0), 0)) }} x {{ Rate }} = </span><span class="cart-page-footer-summary__subtotal-amount notranslate"> {{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + (ele2.price*ele2.quantity), 0), 0) * Rate) }} ฿</span>
              </div>

              <div
                class="cart-page-footer__second-summary text-right"
                style="margin-top: 8px;"
              >
                {{ $t('exRate') }} 1 ¥ = {{ Rate }} {{ $t('baht') }}
              </div>

              <button
                class="btn btn-gradient-primary w-100 mt-1"
                @click="Submit()"
              >
                {{ $t('key-120') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
      
    />
  </div>
</template>

<script>
import { BFormRadio, BFormTextarea, BFormCheckbox } from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  name: 'CheckoutView',
  components: {
    BFormRadio,
    BFormTextarea,
    BFormCheckbox,
    // ThisHeader,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      keyword: '',
      transport: 'car',
      options: [
        { text: 'First radio', value: 'first' },
        { text: 'Second radio', value: 'second' },
      ],
      order: [],
      orderId: [],
      Rate: 0,
      delivery_type: 0,
      note: '',
      packbox: false,
      checkproduct: false,
    }
  },
  computed: {},
  mounted() {
    this.order = JSON.parse(localStorage.getItem('Order'))
    this.order.forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      this.packbox = element.packbox
      this.checkproduct = element.checkproduct
    })
    this.orderId = JSON.parse(localStorage.getItem('OrderId'))
    this.GetRate()
    // console.log(this.order)
    // console.log(this.orderId)
  },
  methods: {
    Submit() {
      if (this.delivery_type === 0) {
        this.Alert(false, 'เกิดข้อผิดพลาด', 'โปรดเลือกขนส่งระหว่างประเทศจีน - ไทย')
      }
      if (this.delivery_type > 0) {
        const obj = {
          order: this.orderId,
          delivery_type: this.delivery_type,
          note: this.note,
        }
        this.$swal({
          title: 'ยืนยันที่จะสั่งสินค้าหรือไม่ ?',
          // text: `หรือไม่ ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.$http
              .post('/order/store', obj)
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                localStorage.setItem('Order', '{}')
                localStorage.setItem('OrderId', '{}')
                this.$router.push({ name: 'order-list' })
              })
              .catch(error => {
                console.log(error)
                this.Alert(false, 'เกิดข้อผิดพลาด', 'ไม่สามารถสั่งสินค้าได้')
              })
          } else {
            this.$swal({
              title: 'ยกเลิกคำสั่งซื้อ',
              text: 'คุณต้องการยกเลิกคำสั่งซื้อหรือไม่ ?',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            })
          }
        })
        console.log(obj)
      }
    },
    async ChangeHeadShop(Shop,shopin) {
      this.$swal({
        title: 'ยืนยันการทำรายการหรือไม่ ?',
        // text: `หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            shops_id: Shop.shops_id,
            packbox: this.packbox,
            checkproduct: this.checkproduct,
          }
          console.debug(obj)
          this.$http.post('/cart/CheckAndPack', obj)
            .then(response => {
              if (response.data) {
                this.order[shopin].packbox = this.packbox
                this.order[shopin].checkproduct = this.checkproduct
              
              localStorage.setItem('Order', JSON.stringify(this.order))
            }
          })

          
            .catch(error => {
              console.log(error)
              this.Alert(false, 'เกิดข้อผิดพลาด', 'ไม่สามารถสั่งสินค้าได้')
            })
        } else {
          if (!result.isConfirmed) {
            this.packbox = Shop.packbox
            this.checkproduct = Shop.checkproduct
            // if (Shop.packbox) {
            //   // eslint-disable-next-line no-param-reassign
            //   Shop.packbox = result.isConfirmed
            // }
            // if (Shop.checkproduct) {
            //   // eslint-disable-next-line no-param-reassign
            //   Shop.checkproduct = result.isConfirmed
            // }
          }
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
      // try {
      //   const obj = {
      //     // eslint-disable-next-line no-underscore-dangle
      //     shops_id: Shop.shops_id,
      //     packbox: Shop.packbox,
      //     checkproduct: Shop.checkproduct,
      //   }
      //   await this.$http.post('/cart/CheckAndPack', obj)
      //   localStorage.setItem('Order', JSON.stringify(this.order))
      // } catch (error) {
      //   console.log(error)
      // }
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get('/rate')
        this.Rate = res.rate_preorder
      } catch (error) {
        console.log(error)
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
   .V-sVj2 div,h2{
      font-size: 10px !important;
    }

    .jNp+ZB{
      font-size:10px;
    }
  }
</style>
